import clsx from 'clsx';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Box, Card, Grid, makeStyles, Typography} from '@material-ui/core';
import {statusDeposit, statusWithdrawHistory} from 'utils/transaction-status.util';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    margin: '10px 0 10px 0',
  },
  box: {
    border: '1px solid #F6BE00',
    padding: theme.spacing(1),
    color: 'white',
  },
}));

const DepositTile = props => {
  const {className, data, type, ...rest} = props;
  const {createdAt, amount, status} = data;
  const classes = useStyles();

  // console.log(`status`, status);
  const label = type === 'deposit' ? statusDeposit(status) : statusWithdrawHistory(status);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box borderRadius={12} className={classes.box}>
        <Grid container direction="column">
          <Grid container item direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">{get(data, 'user.user_pass.username', '-')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {/*{/^[1,5,6,7,8]/.test(status) && (*/}
                {/*  <Chip label={label} size="small" color="primary" deleteIcon={<DoneIcon />} />*/}
                {/*)}*/}
                {/*{/^[0,2,3,4,300,500]/.test(status) && (*/}
                {/*  <Chip label={label} size="small" color="secondary" deleteIcon={<DoneIcon />} />*/}
                {/*)}*/}
                {label}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                <span>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                <span>
                  {amount?.toLocaleString('en', {
                    minimumFractionDigits: 2,
                  })}
                </span>{' '}
                บาท
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

DepositTile.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.string,
};
export default DepositTile;

import HomeTabs from './HomeTabs';
import LoginBar from './LoginBar';
import Page from 'components/Page';
import React, {useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import {BsPerson} from 'react-icons/bs';
import {fetchDepositHistoryPublic} from 'store/deposit.slice';
// import {html} from '../../../constants/news.constant';
import OnlineUserBridge from 'bridges/OnlineUser';
import useSocketOnlineUser from 'hooks/useSocketOnlineUser';
import {IoGameControllerOutline} from 'react-icons/io5';
import {ExitToApp as LogoutIcon, Person as PersonIcon} from '@material-ui/icons';
import {signOut} from 'store/actions/authActions';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  login: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '160px',
    backgroundImage: `url("/assets/images/home/login-1232x160.png")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      backgroundSize: '0 0',
    },
  },
  transactions: {
    display: 'flex',
    width: '100%',
    height: '1096px',
    padding: '123px 5px 0px 5px',
    backgroundImage: `url("/assets/images/home/transactions-1232x940.png")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      backgroundSize: '0 0',
      padding: 0,
    },
  },
  name: {
    color: theme.palette.color.gold,
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const {initialSocket, online} = useSocketOnlineUser();
  // const authLoading = useSelector(state => state.auth.loading);

  const onSignOut = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: 'ยืนยันออกจากระบบ',
      text: 'คุณต้องการออกจากระบบใช่หรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ออกจากระบบ',
      cancelButtonText: 'ยกเลิก',
    }).then(result => {
      if (result.isConfirmed) {
        signOut(dispatch);
        Swal.fire('ออกจากระบบสำเร็จ!', '', 'success');
      }
    });
  };

  const renderUser = () => (
    <Box borderRadius="borderRadius" boxShadow={3} p={2} mb={2}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Grid md={6} xs={12} item>
          <Typography className={classes.name} variant="h3">
            <BsPerson style={{fontSize: 30, position: 'relative', top: '5px'}} /> {currentUser?.name}
          </Typography>
        </Grid>
        <Grid md={6} xs={12} item>
          <Typography color="primary" variant="h3">
            <IoGameControllerOutline style={{fontSize: 30, position: 'relative', top: '5px'}} />{' '}
            {currentUser?.user_pass?.username}
          </Typography>
        </Grid>
        <Grid md={6} xs={12} item>
          <Button
            fullWidth
            size="large"
            className={classes.button}
            color="primary"
            variant="contained"
            href="/auth/dashboard"
            startIcon={<PersonIcon style={{fontSize: 30}} />}>
            <Typography variant="h4">เข้าหน้าสมาชิก</Typography>
          </Button>
        </Grid>
        <Grid md={6} xs={12} item>
          <Button
            fullWidth
            size="large"
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={onSignOut}
            startIcon={<LogoutIcon style={{fontSize: 30}} />}>
            <Typography variant="h4">ออกจากระบบ</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const handleError = e => {
    e.target.onerror = null;
    e.target.style.display = 'none';
    // e.target.src = '/assets/images/notfound.png'
  };

  // useEffect(() => {
  //   popup({
  //     icon: null,
  //     timer: 0,
  //     confirmButtonText: 'OK',
  //     html,
  //   });
  // }, []);

  useEffect(() => {
    const promise = dispatch(fetchDepositHistoryPublic());
    initialSocket('user', 'allUserRoom');

    return () => {
      promise && promise.abort();
    };
  }, []);

  return (
    <Page className={classes.root} title="หน้าหลัก">
      <Container>
        <Box>
          <img src="/assets/images/home/banner-1232x437.png" width="100%" alt="banner" onError={handleError} />
        </Box>
        {!currentUser ? (
          <Box className={classes.login}>
            <LoginBar />
          </Box>
        ) : (
          renderUser()
        )}
        <OnlineUserBridge feature="allUser" count={online} className="py-4" />
        <Box>
          <img src="/assets/images/home/ad-1232x310.png" width="100%" alt="ad01" onError={handleError} />
        </Box>
        <Box>
          <img src="/assets/images/home/ad-02-1232x310.png" width="100%" alt="ad02" onError={handleError} />
        </Box>
        <Box>
          <img src="/assets/images/home/ad-03-1232x410.png" width="100%" alt="ad03" onError={handleError} />
        </Box>
        <Box className={classes.transactions}>
          <HomeTabs />
        </Box>
        <Box>
          <img src="/assets/images/home/bank-1232x200.png" width="100%" alt="bank" onError={handleError} />
        </Box>
      </Container>
    </Page>
  );
};

export default Home;

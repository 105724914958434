import {Button, withStyles} from '@material-ui/core';

const CustomButton = withStyles(theme => ({
  root: {
    fontSize: 20,
    border: '1px solid #FFCA00',
    // color: theme.palette.background.dark,
    // backgroundImage: props => props.color === 'primary' && '-webkit-linear-gradient(top, #F5D16E, #B38220)',
    // background: 'transparent',
    borderRadius: '20px',
    fontWeight: 'bolder',
    textDecoration: 'none',
    cursor: 'pointer',
    boxShadow: 'none',
    textTransform: 'none',
    lineHeight: 1.5,
    // '&:hover': {
    //   background: props => props.color === 'primary' && '#B38220',
    //   backgroundImage: props => props.color === 'primary' && '-webkit-linear-gradient(top, #B38220, #F5D16E)',
    // },
    '&:active': {
      boxShadow: 'none',
      borderColor: theme.palette.color.gold,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}))(Button);

export default CustomButton;

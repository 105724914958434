import api from '../api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {gameList} from '../api/apiHelper';

export const fetchGameList = createAsyncThunk('game/fetchGameList', async (arg, {signal}) => {
  const {gameType} = arg;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(gameList({gameType}, {cancelToken: source.token}));

  return response.data;
});

const extraReducers = {
  [fetchGameList.pending]: (state, action) => {
    const {gameType} = action.meta.arg;
    state.slot[gameType] = {...action.payload, loading: true};
  },
  [fetchGameList.fulfilled]: (state, action) => {
    const {gameType} = action.payload;
    state.slot[gameType] = {...action.payload, loading: false};
  },
};

const initialState = {
  gameCategory: 'sport',
  slot: {
    JK: {},
    AC: {},
    SG: {},
    XE: {},
  },
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameCategory: (state, action) => {
      state.gameCategory = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setGameCategory} = gameSlice.actions;

export default gameSlice.reducer;

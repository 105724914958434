import {toast} from 'react-toastify';
import {useEffect, useMemo} from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// toast, text
const OnlineUser = ({feature, count, type = 'text'}) => {
  // const {socketFlipCard, socketWheel} = useSocketContext();
  const notify = () => toast(`${count} คน กำลัง${whatPlay}`);
  const isToast = type === 'toast';

  const whatPlay = useMemo(() => {
    switch (feature) {
      case 'all': {
        return 'Online';
      }
      case 'flipCard': {
        return 'กำลังเปิดไพ่';
      }
      case 'wheel': {
        return 'กำลังหมุนวงล้อ';
      }
      case 'allUser': {
        return 'ออนไลน์';
      }
      default:
        return 'ออนไลน์';
    }
  }, []);

  useEffect(() => {
    count && isToast && notify();
  }, [count]);

  if (isToast) {
    return null;
  }

  if (!count) {
    return <div className="w-6 h-[30px]" />;
  }

  return (
    <div className="flex items-center space-x-2 font-semibold">
      <div className="relative flex items-center justify-center">
        <div className="w-6 h-6 online-user-icon-animate rounded-full absolute" />
        <AccountCircleIcon style={{fontSize: 30, color: '#D0A236'}} />
      </div>
      <div className="text-lg online-user-text-animate">{count.toLocaleString('en')}</div>
      <div className="text-lg online-user-text-animate">{whatPlay}</div>
    </div>
  );
};

export default OnlineUser;

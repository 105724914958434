import api from 'api/api';
import {accountName, getInviter, register} from 'containers/Register/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchRegister = createAsyncThunk('register/fetchRegister', async arg => {
  const response = await api(register(arg));
  return response.data;
});

export const fetchRegisterInviter = createAsyncThunk('register/fetchInviter', async arg => {
  const response = await api(getInviter(arg));
  return response.data;
});

export const fetchRegisterAccountName = createAsyncThunk('register/fetchAccountName', async arg => {
  const response = await api(accountName(arg));
  return response.data;
});

const initialState = {
  step1: {},
  step2: {},
};

const name = 'register';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setStep1: (state, action) => {
      state.step1 = action.payload;
    },
    setStep2: (state, action) => {
      state.step2 = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {reset, setStep1, setStep2} = slice.actions;

export default slice.reducer;

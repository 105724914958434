import Chip from '../components/Shared/Chip';

export const statusDeposit = status => {
  switch (status) {
    case 0:
      return <Chip size="small" label="ล้มเหลว" color="secondary" />;
    case 1:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 3:
      return <Chip size="small" label="Not Found" />;
    case 4:
      return <Chip size="small" label="Timeout" />;
    case 5:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 6:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 7:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 8:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 300:
      return <Chip size="small" label="ดึงกลับ" />;
    case 500:
      return <Chip size="small" label="ธนาคารแจ้งยกเลิก" />;
    default:
      return '-';
  }
};

export const statusWithdraw = status => {
  switch (status) {
    case 0:
      return <Chip size="small" label="ล้มเหลว" color="secondary" />;
    case 1:
      return <Chip size="small" label="รอดำเนินการ" />;
    case 2:
      return <Chip size="small" label="ยกเลิก" color="secondary" />;
    case 4:
      return <Chip size="small" label="หมดเวลา" />;
    case 11:
      return <Chip size="small" label="กำลังถอนเงินจากระบบ" />;
    default:
      return <Chip size="small" label="กำลังทำรายการ" />;
  }
};

export const statusWithdrawHistory = status => {
  switch (status) {
    case 0:
      return <Chip size="small" label="ล้มเหลว" color="secondary" />;
    case 1:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 2:
      return <Chip size="small" label="ยกเลิก" color="secondary" />;
    case 3:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    default:
      return <Chip size="small" label="กำลังทำรายการ" />;
  }
};

export const statusRewardHistory = status => {
  switch (status) {
    case 0:
      return <Chip size="small" label="รอยืนยัน" />;
    case 1:
      return <Chip size="small" label="สำเร็จ" color="primary" />;
    case 2:
      return <Chip size="small" label="ยกเลิก" color="secondary" />;
    default:
      return <Chip size="small" label="รอยืนยัน" />;
  }
};

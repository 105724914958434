import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAnnouncement} from 'containers/Announcement/apiHelper';

export const fetchAnnouncement = createAsyncThunk('announcement/fetchAnnouncement', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(getAnnouncement({cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchAnnouncement.pending]: (state, action) => {
    state.news = {...action.payload, loading: true};
  },
  [fetchAnnouncement.fulfilled]: (state, action) => {
    state.news = {...action.payload, loading: false};
  },
};

const name = 'announcement';

const initialState = {
  news: {
    data: {},
  },
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setAnnouncement: (state, {payload}) => {
      state.news.data = payload;
    },
  },
  extraReducers,
});

export const {reset, setAnnouncement} = slice.actions;

export default slice.reducer;

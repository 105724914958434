import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import OnlineUser from 'components/OnlineUser';
import {CLIENT_IDS} from 'constants/clients.constant';

const blackList = [CLIENT_IDS.IFINCLUB, CLIENT_IDS.GKBET888];
const {REACT_APP_CLIENT_ID: CLIENT_ID} = process.env;

const OnlineUserBridge = props => {
  const {className, ...rest} = props;
  if (!isEmpty(blackList) && blackList.includes(CLIENT_ID)) {
    return null;
  }

  return (
    <div className={clsx('flex w-full justify-center', className)}>
      <OnlineUser {...rest} />
    </div>
  );
};

export default OnlineUserBridge;

import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {settingGlobal} from 'api/apiHelper';

export const fetchSettingGlobal = createAsyncThunk('global/fetchSettingGlobal', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(settingGlobal({cancelToken: source.token}));

  return response.data;
});

const extraReducers = {
  [fetchSettingGlobal.pending]: (state, action) => {
    state.setting = {...action.payload, loading: true};
  },
  [fetchSettingGlobal.fulfilled]: (state, action) => {
    const {data} = action.payload;
    state.setting = {...data, loading: false};
  },
};

const initialState = {
  setting: {},
  modal: false,
  error: {},
};

const name = 'global';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setApiError: (state, action) => {
      state.error = {...state, ...action.payload};
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {setApiError, setModal, reset} = slice.actions;

export default slice.reducer;

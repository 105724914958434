import clsx from 'clsx';
import get from 'lodash/get';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import React from 'react';
import {Box, Card, makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {statusDeposit, statusWithdrawHistory} from 'utils/transaction-status.util';

const columnName = [
  {
    label: 'เวลา',
  },
  {
    label: 'รหัสผู้เล่น',
  },
  {
    label: 'จำนวน',
  },
  {
    label: 'สถานะ',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
  },
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderColor: 'white',
    },
  },
  tableRowColor: {
    backgroundColor: 'transparent',
  },
}));

const TableHeadCellComponent = ({name}) => <TableCell align="center">{name}</TableCell>;

const TableBodyRowComponent = ({type, depositData, classNameStyle}) => {
  const {status} = depositData;
  const label = type === 'deposit' ? statusDeposit(status) : statusWithdrawHistory(status);
  return (
    <TableRow className={classNameStyle}>
      <TableCell align="center">{moment(depositData?.createdAt).format('DD/MM/YYYY H:mm')}</TableCell>
      <TableCell align="center">{get(depositData, 'user.user_pass.username', '-')}</TableCell>
      <TableCell align="center">{depositData?.amount}</TableCell>
      <TableCell align="center">
        {label}
        {/*{/^[1,5,6,7,8]/.test(depositData?.status) && (*/}
        {/*  <Chip label={getStatusDposit(depositData.status)} color="primary" />*/}
        {/*)}*/}
        {/*{/^[0,3,4,300,500]/.test(depositData?.status) && (*/}
        {/*  <Chip label={getStatusDposit(depositData.status)} color="secondary" />*/}
        {/*)}*/}
      </TableCell>
    </TableRow>
  );
};

const DepositList = ({className, depositDataList, type, ...rest}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor}>
                {columnName.map((column, index) => (
                  <TableHeadCellComponent key={index} name={column.label} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {depositDataList?.map((depositData, index) => (
                <TableBodyRowComponent
                  key={index}
                  classNameStyle={classes.tableRowColor}
                  depositData={depositData}
                  type={type}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

DepositList.propTypes = {
  className: PropTypes.string,
  depositDataList: PropTypes.array,
};

export default DepositList;

import {TextField, withStyles} from '@material-ui/core';

const Input = withStyles(() => ({
  root: {
    '& label': {
      fontSize: 18,
      // fontWeight: 'bold',
      // color: '#fff',
    },
    '& label.Mui-focused': {
      // color: theme.palette.background.main,
    },
    '& .MuiInput-underline:after': {
      // borderBottomColor: theme.palette.background.main,
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        // padding: '8px 14px',
        fontSize: 18,
      },
      '& input:-webkit-autofill': {
        borderRadius: '10px',
        ' -webkit-text-fill-color': 'white',
        '-webkit-box-shadow': '0 0 0px 1000px #000 inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
      '& fieldset': {
        borderRadius: '10px',
        // borderWidth: '1px',
        // borderColor: theme.palette.background.main,
      },
      '&:hover fieldset': {
        // borderColor: theme.palette.background.main,
      },
      '&.Mui-focused fieldset': {
        // borderWidth: '1px',
        // borderColor: theme.palette.background.main,
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
    },
  },
}))(TextField);

export default Input;

import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorModal from 'components/ErrorModal';
import GlobalStyles from './components/GlobalStyles';
import React, {Suspense, useEffect} from 'react';
import routes from './routes';
import theme from './theme';
import {fetchUserDetail, fetchUserSetting} from 'store/user.slice';
import {get, isEmpty} from 'lodash';
import {getCurrentUser} from 'store/actions/authActions';
import {ThemeProvider} from '@material-ui/core';
import {ToastContainer} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {useRoutes} from 'react-router-dom';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  // const {setting} = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user?.profile?.uuid);
  const routing = useRoutes(routes(!isEmpty(currentUser)));
  const setting = useSelector(state => get(state, 'user.setting.data', {}));

  useEffect(() => {
    getCurrentUser(dispatch);
    const promise = !isEmpty(currentUser) && dispatch(fetchUserDetail());
    let promiseSetting;
    if (isEmpty(setting) && !isEmpty(currentUser)) {
      promiseSetting = dispatch(fetchUserSetting());
    }

    return () => {
      promise && promise.abort();
      promiseSetting && promiseSetting.abort();
    };
  }, []);

  // console.log(`!isEmpty(currentUser)`, !isEmpty(currentUser));
  return (
    <ThemeProvider theme={theme}>
      <ErrorModal />
      <ToastContainer
        autoClose={2000}
        role="alert"
        position="bottom-left"
        hideProgressBar
        closeOnClick
        pauseOnHover={false}
        draggable={false}
        theme="dark"
        className="online-user-toast"
        toastClassName="custom-toast"
        // progressClassName="fancy-progress-bar"
      />
      <GlobalStyles />
      <Suspense
        fallback={
          <div className="flex flex-col justify-center items-center h-full bg-black">
            <CircularProgress />
            <span>Loading..</span>
          </div>
        }>
        {routing}
      </Suspense>
    </ThemeProvider>
  );
};

export default App;

import Button from '../../Button';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {SportsEsports as PlayIcon} from '@material-ui/icons';

const GradientPlayIcon = () => (
  <>
    <svg width={0} height={0}>
      {/*<linearGradient id="linearColors" x1="0.5" y1="1" x2="0.5" y2="0">*/}
      {/*  <stop offset="0%" stopColor="#ff0000" />*/}
      {/*  <stop offset="100%" stopColor="#5a67d8" />*/}
      {/*  <stop offset="48%" stopColor="rgba(255, 0, 0, 0)" />*/}
      {/*</linearGradient>*/}
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="rgba(241,184,74,1)" />
        <stop offset={1} stopColor="rgba(207,113,8,1)" />
      </linearGradient>
    </svg>
    <PlayIcon style={{fontSize: 25, fill: 'url(#linearColors)'}} />
  </>
);

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.background.dark,
    whiteSpace: 'nowrap',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));
const LoginButton = props => {
  const {...rest} = props;
  const classes = useStyles();

  return (
    <Button
      fullWidth
      color="primary"
      className={classes.root}
      type="submit"
      variant="contained"
      startIcon={<GradientPlayIcon />}
      {...rest}>
      เข้าสู่ระบบ
    </Button>
  );
};

export {LoginButton};

import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import Input from 'components/Shared/Input';
import OnlineUserBridge from 'bridges/OnlineUser';
import Page from 'components/Page';
import React, {useEffect, useState} from 'react';
import setAuthorizationToken from 'utils/setAuthorizationToken';
import useSocketOnlineUser from 'hooks/useSocketOnlineUser';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Box, InputAdornment, makeStyles, Typography} from '@material-ui/core';
import {fetchAuthLogIn, setProfile, setSetting} from 'store/user.slice';
import {Formik} from 'formik';
import {LoginButton, RegisterButton} from 'components/Login';
import {Lock as PasswordIcon, Person as PersonIcon} from '@material-ui/icons';
import {setAnnouncement} from 'store/announcement.slice';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  login: {
    [theme.breakpoints.up('sm')]: {
      border: `1px solid ${theme.palette.background.main}`,
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '8px 0px',
        fontSize: 24,
      },
    },
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {initialSocket, online} = useSocketOnlineUser();

  const [isShow, setIsShow] = useState(false);
  const [error, setError] = useState('');

  const handleClickShowPassword = () => {
    setIsShow(!isShow);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    initialSocket('user', 'allUserRoom');
  }, []);

  const messageMap = {
    'Request failed with status code 400': 'ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง',
    "Cannot read properties of undefined (reading 'status')": 'ลองใหม่อีกครั้ง',
  };

  return (
    <Page title="เข้าสู่ระบบ">
      <div className="flex justify-center pt-24 md:pt-36 w-full h-screen">
        <div className="w-full md:w-96">
          <OnlineUserBridge feature="allUser" count={online} className="py-4" />
          <Box p={2} className={classes.login} boxShadow={3} borderRadius={10}>
            <Typography align="center" variant="h2" color="primary" gutterBottom>
              ระบบสมาชิก
            </Typography>
            <Formik
              initialValues={{username: '', password: ''}}
              validationSchema={Yup.object().shape({
                username: Yup.string().max(64).required('กรุณากรอกชื่อผู้ใช้ครับ'),
                password: Yup.string().max(64).required('กรุณากรอกรหัสผ่านครับ'),
              })}
              onSubmit={async values => {
                const {username, password} = values;

                dispatch(fetchAuthLogIn({username, password}))
                  .then(unwrapResult)
                  .then(originalPromiseResult => {
                    if (!originalPromiseResult.success) {
                      setError(originalPromiseResult.message);
                      return;
                    }

                    dispatch({type: 'LoginSuccess', payload: originalPromiseResult});

                    const token = get(originalPromiseResult, 'token');
                    const user = get(originalPromiseResult, 'user');

                    if (token && user) {
                      setAuthorizationToken(token, user);
                      dispatch(setProfile(user));
                    }

                    if (originalPromiseResult?.setting) {
                      dispatch(setSetting({data: originalPromiseResult.setting}));
                    }

                    if (originalPromiseResult?.announcement) {
                      dispatch(setAnnouncement(originalPromiseResult.announcement));
                    }
                  })
                  .catch(error => {
                    if (typeof error.response === 'undefined') {
                      setError('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
                    }

                    /** return integer case 1, 2, 3 */
                    if (error.error) {
                      setError(error?.message || error.toString());
                    }

                    // setError(messageMap[error?.message] || error?.toString());
                  });
              }}>
              {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    autoFocus
                    error={!!(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="เบอร์โทร / ยูสเซอร์ไอดี"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                    className={classes.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Input
                    error={!!(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="รหัสผ่าน"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={isShow ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    className={classes.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PasswordIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {isShow ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box textAlign="center" pt={1} pb={2}>
                    <Typography color="error" variant="h4">
                      {error}
                    </Typography>
                  </Box>
                  <div className="flex flex-col space-y-4">
                    <LoginButton disabled={isSubmitting} />
                    <RegisterButton href="/register" />
                  </div>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      </div>
    </Page>
  );
};

export default LoginView;

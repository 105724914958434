// import NavBar from './NavBar';
import TopBar from './TopBar';
import {BottomBar} from 'components/Shared/AppBar';
// import {getUserBalance, getUserPoint} from '../../store/actions/userActions';
import {makeStyles} from '@material-ui/core';
import {Outlet} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.main,
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url("/assets/images/bg-body.jpeg")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100vh',
    overflow: 'auto',
    paddingTop: 64,
    paddingBottom: 200,
    // [theme.breakpoints.up('md')]: {
    //   paddingTop: 64,
    // },
  },
}));

const DashboardLayout = () => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  // const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // const currentUser = useSelector(state => state?.auth?.currentUser);

  // useEffect(() => {
  //   if (currentUser?.user_pass) {
  //     getUserBalance(dispatch, currentUser?.user_pass?.username);
  //     getUserPoint(dispatch, currentUser?.uuid);
  //   }
  // }, [currentUser?.user_pass, currentUser?.uuid]);

  return (
    <div className={classes.root}>
      {/*<TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />*/}
      <TopBar />
      {/*<NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />*/}
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Outlet />
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default DashboardLayout;

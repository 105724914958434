import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import Input from 'components/Shared/Input';
import React, {useState} from 'react';
import setAuthorizationToken from 'utils/setAuthorizationToken';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {fetchAuthLogIn, setProfile, setSetting} from 'store/user.slice';
import {Formik} from 'formik';
import {Grid, InputAdornment, makeStyles, Typography} from '@material-ui/core';
import {LoginButton, RegisterButton} from 'components/Login';
import {Lock as PasswordIcon, Person as PersonIcon} from '@material-ui/icons';
import {setAnnouncement} from 'store/announcement.slice';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    width: '100%',
    padding: '64px 24px 0 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  input: {
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));

const LoginBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [isShow, setIsShow] = useState(false);

  const handleClickShowPassword = () => {
    setIsShow(!isShow);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().max(64).required('กรุณากรอกชื่อผู้ใช้ หรือรหัสผ่านครับ'),
          password: Yup.string().max(64).required('กรุณากรอกชื่อผู้ใช้ หรือรหัสผ่านครับ'),
        })}
        onSubmit={async (values, {setSubmitting}) => {
          const {username, password} = values;
          dispatch(fetchAuthLogIn({username, password}))
            .then(unwrapResult)
            .then(originalPromiseResult => {
              if (!originalPromiseResult.success) {
                setError(originalPromiseResult.message);
                return;
              }

              dispatch({type: 'LoginSuccess', payload: originalPromiseResult});

              const token = get(originalPromiseResult, 'token');
              const user = get(originalPromiseResult, 'user');

              if (token && user) {
                setAuthorizationToken(token, user);
                dispatch(setProfile(user));
              }

              if (originalPromiseResult?.setting) {
                dispatch(setSetting({data: originalPromiseResult.setting}));
              }

              if (originalPromiseResult?.announcement) {
                dispatch(setAnnouncement(originalPromiseResult.announcement));
              }
            })
            .catch(error => {
              if (typeof error.response === 'undefined') {
                setError('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
              }

              /** return integer case 1, 2, 3 */
              if (error.error) {
                setError(error?.message || error.toString());
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}>
        {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
              <Grid md={4} xs={12} item>
                <Input
                  fullWidth
                  name="username"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  label="เบอร์โทร / ยูสเซอร์ไอดี"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!(touched.username && errors.username)}
                />
              </Grid>
              <Grid md={4} xs={12} item>
                <Input
                  fullWidth
                  label="รหัสผ่าน"
                  margin="dense"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={isShow ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {isShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!(touched.password && errors.password)}
                />
              </Grid>
              <Grid md={2} xs={6} item>
                <LoginButton disabled={isSubmitting} />
              </Grid>
              <Grid md={2} xs={6} item>
                <RegisterButton href="/register" />
              </Grid>
            </Grid>
            <Typography align="center" color="error" variant="h4">
              {error ? error : errors.username || errors.password ? errors.username : null}
            </Typography>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginBar;

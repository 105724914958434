import Chip from '@material-ui/core/Chip';
import {withStyles} from '@material-ui/core/styles';

const CustomChip = withStyles(() => ({
  root: {
    fontSize: 18,
    fontFamily: 'FC Active Italic',
  },
}))(Chip);

export default CustomChip;

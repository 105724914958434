export const TOP_MENUS = [
  {
    name: 'casino',
    to: '/casino',
    img: '/assets/images/top/casino-120x40.png',
    disabled: true,
  },
  {
    name: 'slot',
    to: '/slot',
    img: '/assets/images/top/slot-120x40.png',
    disabled: true,
  },
  {
    name: 'sport',
    to: '/sport',
    img: '/assets/images/top/sport-120x40.png',
    disabled: true,
  },
  {
    name: 'promotion',
    to: '/promotion',
    img: '/assets/images/top/promotion-120x40.png',
    disabled: false,
  },
  {
    name: 'contact',
    to: '/contact',
    img: '/assets/images/top/contact-120x40.png',
    disabled: false,
  },
];

export const LOGO_MAX_HEIGHT = {
  PAP55: 156,
  DEFAULT: 84,
};

export const LOGO_PRIVATE_MAX_HEIGHT = {
  PAP55: 40,
  DEFAULT: 40,
};

import Button from '../../Button';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {PersonAdd} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'nowrap',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));
const RegisterButton = props => {
  const {...rest} = props;
  const classes = useStyles();

  return (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      className={classes.root}
      startIcon={<PersonAdd />}
      {...rest}>
      สมัครสมาชิก
    </Button>
  );
};

export {RegisterButton};

import DepositList from './DepositList';
import DepositTile from './DepositTile';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import WithdrawList from './WithdrawList';
import {AppBar, Box, Hidden, Tab, Tabs} from '@material-ui/core';
import {FcPlanner} from 'react-icons/fc';
import {fetchWithdrawHistoryPublic} from '../../../store/withdraw.slice';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box key={index} p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function HomeTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const allDepositHistoryList = useSelector(state => get(state, 'deposit.public.data', []));
  const allWithdrawHistoryList = useSelector(state => get(state, 'withdraw.public.data', []));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let promise;
    if (value === 1 && isEmpty(allWithdrawHistoryList)) {
      promise = dispatch(fetchWithdrawHistoryPublic());
    }

    return () => {
      promise && promise.abort();
    };
  }, [value]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: 'transparent',
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="transaction latest">
          <Tab label="รายการฝากล่าสุด" icon={<FcPlanner style={{fontSize: 50}} />} {...a11yProps(0)} />
          <Tab label="รายการถอนล่าสุด" icon={<FcPlanner style={{fontSize: 50}} />} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Hidden smDown>
          <DepositList depositDataList={allDepositHistoryList} type="deposit" />
        </Hidden>
        {allDepositHistoryList && (
          <Hidden mdUp>
            {allDepositHistoryList?.map((depositData, index) => {
              return <DepositTile key={index} data={depositData} type="deposit" />;
            })}
          </Hidden>
        )}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {!isEmpty(allWithdrawHistoryList) && (
          <>
            <Hidden smDown>
              <WithdrawList withdrawDataList={allWithdrawHistoryList} type="withdraw" />
            </Hidden>
            <Hidden mdUp>
              {allWithdrawHistoryList?.map((withdrawData, index) => {
                return <DepositTile key={index} data={withdrawData} type="withdraw" />;
              })}
            </Hidden>
          </>
        )}
      </TabPanel>
    </div>
  );
}

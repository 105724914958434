// import AffiliateView from './views/affiliate/AffiliateView';
// import ChangePasswordView from './views/setting/ChangePasswordView';
import ContactView from './views/contact/ContactView';
// import CreditView from './views/reward/CreditView';
import DashboardLayout from './layouts/DashboardLayout';
// import GamesList from './views/games/GameList';
// import GamesView from './views/games';
import HomePromotion from './views/home/Promotion';
import HomeView from './views/home/HomeView';
import LiveScoreView from './views/home/LiveScoreView';
import LoginView from './views/auth/LoginView';
import MainLayout from './layouts/MainLayout';
import NotFoundView from './views/errors/NotFoundView';
import PromotionView from './views/promotion/PromotionView';
import React, {lazy} from 'react';
// import RegisterView from './views/auth/RegisterView';
// import RewardView from './views/reward/RewardView';
import TableScoreView from './views/home/TableScoreView';
// import WithdrawView from './views/withdraw/WithdrawView';
import {Navigate} from 'react-router-dom';

const Dashboard = lazy(() => import('./containers/Dashboard'));
const GameCategory = lazy(() => import('./containers/Game/Category'));
const GameSlot = lazy(() => import('./containers/Game/Slot'));
const Deposit = lazy(() => import('./containers/Deposit'));
const Withdraw = lazy(() => import('./containers/Withdraw'));
const Contact = lazy(() => import('./containers/Contact'));
const TransactionHistory = lazy(() => import('./containers/TransactionHistory'));
const WinLost = lazy(() => import('./containers/WinLost'));
const Affiliate = lazy(() => import('./containers/Affiliate'));
const Reward = lazy(() => import('./containers/Reward'));
const ChangePassword = lazy(() => import('./containers/Settings/ChangePassword'));
const ManageAddress = lazy(() => import('./containers/Settings/ManageAddress'));
const Register = lazy(() => import('./containers/Register'));
const UserLevel = lazy(() => import('./containers/User/Level'));
const Media = lazy(() => import('./containers/Media'));
const Wheel = lazy(() => import('./containers/Media/Wheel'));
const Movie = lazy(() => import('containers/Media/Movie'));
const FlipCard = lazy(() => import('containers/Media/FlipCard'));
// const Login = lazy(() => import('./containers/Login'));

const routes = isLoggedIn => [
  {
    path: 'auth',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {path: 'dashboard', element: <Dashboard />},
      {path: 'deposit', element: <Deposit />},
      {path: 'withdraw', element: <Withdraw />},
      {path: 'affiliate', element: <Affiliate />},
      {path: 'winlost', element: <WinLost />},
      {path: 'transaction/history', element: <TransactionHistory />},
      {path: 'reward', element: <Reward />},
      {path: 'reward/:categoryId', element: <Reward />},
      // {path: 'wheel', element: <WheelView />},
      {path: 'settings/change-password', element: <ChangePassword />},
      {path: 'settings/manage-address', element: <ManageAddress />},
      {path: 'user/ranking', element: <UserLevel />},
      {path: 'contact', element: <Contact />},
      {
        path: 'media',
        element: <Media />,
        // children: [
        //   {path: 'wheel', element: <Wheel />},
        //   {path: 'movie', element: <Movie />},
        //   {path: 'promotion', element: <PromotionView />},
        // ],
      },
      {path: 'media/wheel', element: <Wheel />},
      {path: 'media/movie', element: <Movie />},
      {path: 'media/flip-card', element: <FlipCard />},
      {path: 'media/promotion', element: <PromotionView />},
      {path: 'games/category', element: <GameCategory />},
      {path: 'games/:type/:slot', element: <GameSlot />},
      {path: '', element: <Navigate to="dashboard" />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  {
    path: '',
    element: isLoggedIn ? <Navigate to="auth" /> : <MainLayout />,
    children: [
      {path: 'login', element: <LoginView />},
      {path: 'register', element: <Register />},
      {path: 'regis/:team/:marketing', element: <Register />},
      {path: 'regiss/:team/:marketing', element: <Register />},
      {path: 'aff/:afCode', element: <Register />},
      {path: 'contact', element: <ContactView />},
      {path: 'live-score', element: <LiveScoreView />},
      {path: 'score', element: <TableScoreView />},
      {path: 'promotion', element: <HomePromotion />},
      {path: '404', element: <NotFoundView />},
      {path: '/', element: <HomeView />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
];

export default routes;
